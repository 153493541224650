.scroll-to-top {
    position: fixed;
    bottom: 100px;
    right: 40px;
    display: none;
    z-index: 1000;
  }
  
  .scroll-to-top.show {
    display: inline-block;
  }
  